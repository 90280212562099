import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { mediaMin } from 'styles/mediaQueries';

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  ${mediaMin.tabletLandscape`
    flex-direction: row;
    align-items: flex-end;
  `}
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  ${mediaMin.tabletLandscape`
    font-size: 40px;
    line-height: 40px;
  `}
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: start;
  ${mediaMin.tabletLandscape`
    width: auto;
    padding: 0;
    justify-content: center;
    ${({ $alignLeft }) =>
      $alignLeft
        ? `
      flex-grow: 1;
      justify-content: left;
      padding-left: 30px;
    `
        : ''}
  `}
`;

const ListingButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  border-bottom: ${(props) => (props.active ? '1px solid #000' : '1px solid transparent')};
  box-sizing: border-box;
  margin-left: 40px;
  font-size: 12px;
  &:first-child {
    margin-left: 0;
  }
  ${mediaMin.phoneLarge`
    font-size: 14px;
  `}
`;

const ListingLink = styled(NavLink)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  margin-left: 40px;
  font-size: 12px;
  &.active {
    border-bottom: 1px solid #000;
  }
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    text-decoration: none;
  }
  ${mediaMin.phoneLarge`
    font-size: 14px;
  `}
`;

const ListingsHeading = ({ filter, setFilter }) => {
  return (
    <Heading>
      <Title>Listings</Title>
      <ButtonRow $alignLeft={true}>
        <ListingLink activeClassName="active" to="/listings">
          MANHATTAN
        </ListingLink>
        <ListingLink activeClassName="active" to="/listings-hamptons">
          HAMPTONS
        </ListingLink>
        <ListingLink activeClassName="active" to="/listings-caribbean">
          CARIBBEAN
        </ListingLink>
        <ListingLink activeClassName="active" to="/listings-montana">
          MONTANA
        </ListingLink>

        {/* <ListingButton
          active={filter === 'RECENT TRANSACTIONS'}
          onClick={() => {
            setFilter('RECENT TRANSACTIONS');
          }}
        >
          RECENT TRANSACTIONS
        </ListingButton> */}
      </ButtonRow>
      <ButtonRow>
        <ListingButton
          active={filter === 'SALES'}
          onClick={() => {
            setFilter('SALES');
          }}
        >
          SALES
        </ListingButton>
        <ListingButton
          active={filter === 'RENTALS'}
          onClick={() => {
            setFilter('RENTALS');
          }}
        >
          RENTALS
        </ListingButton>
        {/* <ListingButton
          active={filter === 'RECENT TRANSACTIONS'}
          onClick={() => {
            setFilter('RECENT TRANSACTIONS');
          }}
        >
          RECENT TRANSACTIONS
        </ListingButton> */}
      </ButtonRow>
    </Heading>
  );
};

export default ListingsHeading;
