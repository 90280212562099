import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Context from 'config/Context';
import { mediaMin } from 'styles/mediaQueries';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = ({ className, onClick }) => {
  return <img src="/images/icons/arrow-right.svg" alt="next arrow" className={className} onClick={onClick} />;
};

const PrevArrow = ({ className, onClick }) => {
  return <img src="/images/icons/arrow-left.svg" alt="previous arrow" className={className} onClick={onClick} />;
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

const SliderContainer = styled.div`
  padding: 60px 0;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  ${mediaMin.tabletLandscape`
    height: calc(100vh - 60px);
    height: unset;
    min-height: 55vh;
    padding: 0;
  `}

  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      margin: 0 auto;
      height: 100%;
      width: 100%;
      ${mediaMin.tabletLandscape`
        width: 75%;
      `}
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }
    }

    .slick-arrow {
      display: block;
      height: 30px;
      width: 30px;
      z-index: 100;
      ${mediaMin.tabletLandscape`
        height: 45px;
        width: 45px;
      `}
    }

    .slick-next {
      right: 10px;
      ${mediaMin.tabletLandscape`
        right: 44px;
      `}
    }

    .slick-prev {
      left: 10px;
      ${mediaMin.tabletLandscape`
        left: 44px;
      `}
    }

    .slick-dots {
      ${mediaMin.tabletLandscape`
        bottom: 64px;
        width: unset;
        left: calc(50% + 64px);
      `}
      li {
        border: 1px solid #000;
        border-radius: 50%;
        height: 12px;
        width: 12px;

        button {
          &::before {
            color: transparent;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.slick-active {
          background-color: #000;
        }
      }
    }
  }
`;

const SliderSlide = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
    flex-direction: row;
  `}
  img {
    width: 70%;
    max-width: 400px;
    ${mediaMin.tabletLandscape`
      max-height: 100%;
      max-width: 50%;
      width: 50%;
      margin: 0 auto;
      padding: 64px;
    `}
  }
  .col {
    display: flex;
    flex-direction: column;
    padding: 24px;
    text-align: center;
    justify-content: center;
    ${mediaMin.tabletLandscape`
      height: unset;
      justify-content: flex-start;
      text-align: left;
      width: 50%;
      padding: 64px;
    `}
    .publication {
      font-size: 16px;
      font-style: italic;
      line-height: 16px;
    }
    .date {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
    h3 {
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      ${mediaMin.tabletLandscape`
        font-size: 24px;
        line-height: 24px;
        margin: 24px 0;
      `}
      ${mediaMin.desktop`
        font-size: 32px;
        line-height: 32px;
        margin: 24px 0;
      `}
    }
    a {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      z-index: 100;
    }
  }
`;

const News = () => {
  const context = useContext(Context);
  const { news, fetchNews } = context;

  const SliderRef = useRef(null);

  const generateSlides = () => {
    if (news) {
      return news.map(el => (
        <SliderSlide key={el.id}>
          <SliderSlide className="container">
            <img src={el.image} alt="" />
            <div className="news-title col">
              <span className="publication">{el.publication}</span>
              <span className="date">{el.date}</span>
              <h3>{el.title}</h3>
              <a href={el.url} target="_blank" rel="noopener noreferrer">
                VIEW ARTICLE
              </a>
            </div>
          </SliderSlide>
        </SliderSlide>
      ));
    }
  };

  useEffect(() => {
    if (!news) fetchNews(SliderRef);
  }, [news]);

  return (
    <SliderContainer>
      <Slider ref={SliderRef} {...settings}>
        {generateSlides()}
      </Slider>
    </SliderContainer>
  );
};

export default News;
