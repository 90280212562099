import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { mediaMax, mediaMin } from 'styles/mediaQueries';
import ContactCard from './ContactCard';

import mail from './mail.svg';
import phone from './phone.svg';
import pin from './pin.svg';
import instagram from './instagram.svg';

import fairHousingNotice from '../../../../../docs/fairhousingnotice.pdf';
import standardOperatingProcedures from '../../../../../docs/modlin_final.pdf';

const ContactContainer = styled.div`
  background: black;
  color: #fff;
  .card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${mediaMin.tablet`
      flex-direction: row;
    `}
  }
  .legal-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaMax.phoneXL`
      flex-direction: column;
    `}
    a {
      color: #fff;
      font-weight: bolder;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      padding-right: 20px;
      ${mediaMax.phoneXL`
        text-align: center;
        padding: 20px;
      `}
    }
  }
`;

function Contact() {
  return (
    <Element name="contact-scroll-element">
      <ContactContainer>
        <div className="container standard-padding">
          <h2 className="section-title center">Contact</h2>
          <div className="card-container">
            <ContactCard iconSrc={phone} iconAlt={'Phone Icon'} details="+1 212 974 0740" linkTo="tel:12129740740" />
            <ContactCard
              iconSrc={mail}
              iconAlt={'Mail Icon'}
              details="info@modlingroup.com"
              linkTo="mailto:info@modlingroup.com"
            />
            <ContactCard
              iconSrc={pin}
              iconAlt={'Pin Icon'}
              details={
                <span>
                  152 West 57th Street
                  <br />
                  New York NY, 10019
                </span>
              }
              linkTo="https://goo.gl/maps/XBJTUqzonWYSWGR78"
            />
          </div>
          <div className="card-container">
            <ContactCard iconSrc={phone} iconAlt={'Phone Icon'} details="+1 631 237 1007" linkTo="tel:16312371007" />
            <ContactCard
              iconSrc={mail}
              iconAlt={'Mail Icon'}
              details="hamptons@modlingroup.com"
              linkTo="mailto:hamptons@modlingroup.com"
            />
            <ContactCard
              iconSrc={pin}
              iconAlt={'Pin Icon'}
              details={
                <span>
                  2405 Main Street
                  <br />
                  Bridgehampton, NY 11932
                </span>
              }
              linkTo="https://goo.gl/maps/VKnAXk77oL86kMVN8"
            />
          </div>
          <div className="card-container">
            <ContactCard
              iconSrc={instagram}
              iconAlt={'Instagram'}
              details="@modlin_group"
              linkTo="https://www.instagram.com/modlin_group/"
            />
          </div>
          <div className="legal-container">
            <a href={fairHousingNotice} target={"_blank"}>Fair Housing Notice</a>
            <a href={standardOperatingProcedures} target={"_blank"}>Standard Operating Procedures</a>
          </div>
        </div>
      </ContactContainer>
    </Element>
  );
}

export default Contact;
