import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'styles/mediaQueries';

const HamburgerButton = styled.button`
  background: none;
  height: 35px;
  width: 30px;
  position: relative;
  padding: 0;
  border: none;
  cursor: pointer;
  z-index: 1;
  ${mediaMin.tabletLandscape`
    display: none;
  `}
  span {
    background: ${props => props.color};
    position: absolute;
    height: 2px;
    display: block;
    width: 100%;
    left: 0;
    transition: background 300ms ease;
    &:nth-child(1) {
      top: 20%;
    }
    &:nth-child(2) {
      top: 50%;
    }
    &:nth-child(3) {
      top: 80%;
    }
  }
`;

function Hamburger({ color, toggleHamburger }) {
  return (
    <HamburgerButton onClick={toggleHamburger} color={color}>
      <span />
      <span />
      <span />
    </HamburgerButton>
  );
}

export default Hamburger;
