import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import { mediaMin } from 'styles/mediaQueries';

const VideoModalContainer = styled.div`
  position: fixed;
  background: #fff;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  z-index: 999;
`;

const CloseButton = styled.button`
  position: fixed;
  z-index: 150;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  top: 36px;
  right: 36px;
  &:focus {
    outline: none;
  }
  ${mediaMin.tabletLandscape`
    top: 24px;
    right: 44px;
  `}
`;

const VideoContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  ${mediaMin.tabletLandscape`
    width: 50%;
  `}
   
  > img {
      width: 100%;
  }
`;

const FloorModal = ({ active, toggleFloorModal, floorUrl}) => {
  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'visible';
  }, [active]);

  return (
    <VideoModalContainer active={active}>
      <CloseButton onClick={() => toggleFloorModal()}>
        <img src="/images/icons/close.svg" alt="close overlay" />
      </CloseButton>
      <VideoContainer>
          <img src={floorUrl} />
      </VideoContainer>
    </VideoModalContainer>
  );
};

export default FloorModal;
