import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'styles/mediaQueries';

const PictureWrapper = styled.picture`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 1s ease;
  img {
    object-fit: cover;
    width: 100%;
  }
  .caption {
    position: absolute;
    bottom: 20%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    margin: 0.5em 0;
    z-index: 100;

    ${mediaMin.tabletLandscape`
        width: auto;
        bottom: 5%;
        right: 5%;
        font-size: 1rem;
    `}
    ${mediaMin.desktop`
        font-size: 1.4rem;
    `}
  }
  .dark-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
`;

const FixedQuote = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  margin-bottom: 14px;
  bottom: 20%;
  width: 80%;
  .quote {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: #fff;
    margin-bottom: 8px;
    font-size: 1.25rem;
    line-height: 2rem;
    ${mediaMin.tabletLandscape`
    margin-bottom: 24px;
    font-size: 2rem;
    line-height: 3rem;
    `}
  }
  .author {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 0.75rem;
    ${mediaMin.tabletLandscape`
    font-size: 1rem;

    `}
  }
`;

const CoverImage = ({ active, alt, ext, src, caption, quote }) => {
  ext = ext || 'jpg';

  return (
    <PictureWrapper active={active}>
      {caption && <h6 className="caption">{caption}</h6>}
      {quote && (
        <FixedQuote>
          <span className="quote">“A private family office for New York City and Hamptons Real Estate.”</span>
          <span className="author">— Adam D. Modlin</span>
        </FixedQuote>
      )}
      <div className="dark-overlay"></div>
      <source
        srcSet={`${src}_400.${ext} 400w, ${src}_600.${ext} 600w, ${src}_800.${ext} 800w, ${src}_1200.${ext} 1200w, ${src}_1600.${ext} 1600w, ${src}_2000.${ext} 2000w, ${src}_2400.${ext} 2400w, ${src}_2800.${ext} 2800w, ${src}_3200.${ext} 3200w`}
      />
      <img src={`${src}_2400.${ext}`} alt={alt} />
    </PictureWrapper>
  );
};

export default CoverImage;
