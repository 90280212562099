/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Context from 'config/Context';
import FactSection from './fact-section';
import HeroImage from './hero-image';
import Map from './map';
import ListingBody from './listing-body';
import ImageGallery from './image-gallery';
import VideoModal from './video-modal';
import FloorModal from './floor-modal';
import Contact from './contact';
import { mediaMin } from 'styles/mediaQueries';

const ListingPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 24px;
  ${mediaMin.tabletLandscape`
        margin: 140px 10%;
    `}
`;

const NavRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  flex-direction: row;
  a {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    ${mediaMin.tabletLandscape`
        font-size: 14px;	
        line-height: 16px;
        `}
    img {
      height: 14px;
      margin-right: 8px;
    }
  }
  div {
    display: flex;
    .spacer {
      margin: 0 4px;
    }
  }
`;

const TitleRow = styled.div`
  display: flex;
  jusfify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
    flex-direction: row;
    `}
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  jusfify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  ${mediaMin.tabletLandscape`
        align-items: ${props => (props.right ? 'flex-end' : 'flex-start')};
        margin-bottom: 0;
    `}
  h1, h2 {
    margin: 0 0 24px 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    ${mediaMin.tabletLandscape`
            line-height: 28px;
        `}
  }
  h3 {
    margin: 0 0 8px 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    ${mediaMin.tabletLandscape`
            font-size: 24px;	
            line-height: 28px;
        `}
  }
  h4 {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
  }
  .listing-id {
    margin: 0 0 8px 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    letter-spacing: 0.1rem;
    ${mediaMin.tabletLandscape`
      font-size: 24px;	
      line-height: 28px;
    `}
  }
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
        flex-direction: row;
    `}
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  ${mediaMin.tabletLandscape`
        margin-bottom: 0;
        width: 60%;
        padding-right: 40px;
    `}
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mediaMin.tabletLandscape`
        width: 40%;
        padding-left: 40px;
    `}
`;

const Listing = ({ match }) => {
  const context = useContext(Context);
  const { fetchListingsData } = context;
  const listings = context.getCurrentListings(match.path);

  // console.log(listings, "LISTINGS")

  const [currentListing, setCurrentListing] = useState(null);
  const [galleryActive, setGalleryActive] = useState(false);

  const [videoModalActive, setVideoModalActive] = useState(false);
  const [videoModalPlaying, setVideoModalPlaying] = useState(false);

  const [nextID, setNextID] = useState(null);
  const [prevID, setPrevID] = useState(null);

  const setNextandPrev = () => {
    const currentIndex = listings.findIndex(listing => `${listing.id}` === match.params.id)

    if (currentIndex !== -1) {
      setPrevID(listings[currentIndex === 0 ? listings.length - 1 : currentIndex - 1].id);
      setNextID(listings[currentIndex === listings.length - 1 ? 0 : currentIndex + 1].id);
    }
  };

  const toggleImageGallery = () => {
    setGalleryActive(!galleryActive);
  };

  const toggleVideoModal = () => {
    setVideoModalActive(!videoModalActive);
    setVideoModalPlaying(!videoModalPlaying);
  };

  const [floorActive, setFloorActive] = useState(false);

  const toggleFloorModal = () => {
    setFloorActive(!floorActive)
  }

  const truncateText = str => (str || '').length > 90 ? str.substring(0, 87) + '...' : str

  useEffect(() => {
    if (!listings) {
      fetchListingsData();
    }
  }, []);

  useEffect(() => {
    if (listings) {
      setCurrentListing(listings.find(listing => `${listing.id}` === match.params.id));
      setNextandPrev();
    }
  }, [listings, match]);

  const generateContacts = agentsArray => agentsArray.map(agent => <Contact agent={agent} />);

  return currentListing ? (
    <>
      <Helmet>
        <title>{currentListing.location.address} - {currentListing.location.place} | Modlin Group</title>
        <meta property="description" content={truncateText(currentListing.listing_details.description)} />
        <meta property="og:title" content={`${currentListing.location.address} - ${currentListing.location.place}`} />
        <meta property="og:description" content={truncateText(currentListing.listing_details.description)} />
        <meta property="og:image" content={currentListing.media.original_image_list[0]} />
      </Helmet>
      <ListingPage>
        <NavRow>
          <Link to={`${context.getCurrentListingsBaseRoute(match.path)}/`}>
            <img src="/images/icons/arrow-left.svg" alt="back arrow" /> <span>ALL LISTINGS</span>
          </Link>{' '}
          <div>
            <Link to={`${context.getCurrentListingsBaseRoute(match.path)}/${prevID}`}>PREVIOUS</Link>
            <span className="spacer">|</span>
            <Link to={`${context.getCurrentListingsBaseRoute(match.path)}/${nextID}`}>NEXT</Link>
          </div>
        </NavRow>
        <TitleRow>
          <TitleColumn>
            <h1>{`${currentListing.location.address}${currentListing.location.place ? ` - ${currentListing.location.place}` : ''}`}</h1>
            <div>
              <h3>{(currentListing.building_details || { building_name: '' }).building_name}</h3>
              <h4>{currentListing.location.cross_streets.length ? `[${currentListing.location.cross_streets.join(', ')}]` : null}</h4>
            </div>
          </TitleColumn>
          <TitleColumn right>
            <div className='listing-id'>{`Listing ID: ${currentListing.id}`}</div>
            <div className="share-row" />
          </TitleColumn>
        </TitleRow>
        <MainRow>
          <LeftColumn>
            <HeroImage
              currentListing={currentListing}
              toggleImageGallery={toggleImageGallery}
              toggleVideoModal={toggleVideoModal}
              toggleFloorModal={toggleFloorModal}
            />
            <ListingBody currentListing={currentListing} />
            <Map currentListing={currentListing} />
          </LeftColumn>
          <RightColumn>
            <FactSection currentListing={currentListing} />
            {generateContacts(currentListing.agents)}
          </RightColumn>
        </MainRow>
        <ImageGallery
          currentListing={currentListing}
          galleryActive={galleryActive}
          toggleImageGallery={toggleImageGallery}
        />
        <VideoModal
          active={videoModalActive}
          toggleVideoModal={toggleVideoModal}
          videoModalPlaying={videoModalPlaying}
          videoUrl={currentListing.media.virtual_tour_url || currentListing.media.video_url}
        />
        <FloorModal
          active={floorActive}
          toggleFloorModal={toggleFloorModal}
          floorUrl={currentListing.media.main_floor_plan}
        />

      </ListingPage>
    </>
  ) : (
    <>
      <Helmet>
        <title>Modlin Group | Listing</title>
      </Helmet>
      <ListingPage>Loading</ListingPage>
    </>
  );
};

export default withRouter(Listing);
