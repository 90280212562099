import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'styles/mediaQueries';
import { variables } from 'styles/variables';

const TeamCardContainer = styled.div`
  border: 1px solid ${variables.colors.lightGray};
  box-sizing: border-box;
  margin: 8px 0;
  width: 100%;
  ${mediaMin.tablet`
    width: calc(50% - 8px);
    max-width: 772px;
  `}
  .image-container {
    position: relative;
    display: flex;
    .image-cta {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: all 300ms ease;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 2em;
      ${mediaMin.tabletLandscape`
        &:hover {
          opacity: 1;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
      `}
    }
    img {
      display: block;
      width: 100%;
      align-self: center;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    .name {
      font-size: 24px;
      line-height: 28px;
      margin: 0;
    }
    .title {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      margin-bottom: 24px;
    }
    .phone {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
    button {
      margin-top: 24px;
      background: none;
      border: none;
      display: flex;
      padding: 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .email {
      margin: 10px 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
    }
  }
`;

const TeamCard = ({ employee, toggleTeamModalOpen, setCurrentTeamModalEmployee }) => {
  const { name, position, phone, phone_extension, phoneHref, cell, email, headshot } = employee;

  const formatPhoneNumber = phoneNumberString => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[2]}.${match[3]}.${match[4]}`;
    }
    return null;
  };

  return (
    <TeamCardContainer>
      <div className="image-container">
        <div
          className="image-cta"
          onClick={() => {
            setCurrentTeamModalEmployee(employee);
            toggleTeamModalOpen(true);
          }}
        >
          <p>Read Bio</p>
        </div>
        <img src={headshot} alt={name} />
      </div>
      <div className="text-container">
        <h3 className="name">{name}</h3>
        <span className="title">{position}</span>
        {phone && (
          <span className="phone">
            <a href={`tel:${phone}${phone_extension && `,${phone_extension}`}`}>
              P: {formatPhoneNumber(phone)} {phone_extension && `[ext ${phone_extension}]`}
            </a>
          </span>
        )}
        {cell && (
          <span className="phone">
            <a href={`tel:${cell.replace(/[^0-9]/g, '')}`}>C: {formatPhoneNumber(cell)}</a>
          </span>
        )}
        <button
          onClick={() => {
            setCurrentTeamModalEmployee(employee);
            toggleTeamModalOpen(true);
          }}
        >
          READ BIO
        </button>
        <a href={`mailto:${email}`}>
          <span className="email">EMAIL ME</span>
        </a>
      </div>

      {/* <span className="download">{download}</span> */}
    </TeamCardContainer>
  );
};

export default TeamCard;
