import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import { variables } from 'styles/variables';

const mapOptions = {
  minZoom: 15.5,
  maxZoom: 18,
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  clickableIcons: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  scrollwheel: false,
  gestureHandling: 'none'
};

const GoogleMapContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 40vh;
  border: 1px solid ${variables.colors.lightGray};
`;

const Map = ({ currentListing }) => {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    setCenter({ lat: currentListing.location.coordinates[0], lng: currentListing.location.coordinates[1] });
  }, [currentListing]);

  useEffect(() => {
    if (map && maps) {
      const tempMarker = new maps.Marker({
        position: { lat: currentListing.location.coordinates[0], lng: currentListing.location.coordinates[1] },
        label: {
          color: '#000000',
          fontSize: '12px',
          text: currentListing.location.address
        }
      });
      tempMarker.setMap(map);
    }
  }, [map, maps, currentListing]);

  return (
    <GoogleMapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCgtV7xGHYjiQO61iL5QsR1oBCmnF_1OaI' }}
        center={center}
        options={mapOptions}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMaps(maps);
        }}
      ></GoogleMapReact>
    </GoogleMapContainer>
  );
};

export default Map;
