import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import { mediaMax, mediaMin } from 'styles/mediaQueries';

import closeIcon from 'assets/icons/icon-close.svg';

const modalStyles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 999
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const StyledReactModal = styled(ReactModal)`
  overflow: ${props => (props.styledPropOpen ? 'scroll' : 'hidden')};
  .close-button {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 30px;
    right: 20px;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    ${mediaMin.tablet`
      top: 40px;
      right: 40px;
    `}
    img {
      height: 100%;
      width: 100%;
      height: 20px;
      width: 20px;
    }
  }
  .modal-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    ${mediaMin.tablet`
      flex-direction: row;
      width: 80%;
      height: 70%;
      padding-top: 0;
    `}
    .left {
      width: 100%;

      ${mediaMin.tablet`
        width: 50%;
        display: flex;
        justify-content: flex-end;
      `}
      img {
        display: block;
        max-width: 500px;
        ${mediaMax.tablet`
          margin: 0 auto;
          width: 100%;
        `}
        ${mediaMin.tablet`
          align-self: flex-start;
          width: 400px;
        `}
      }
    }
    .right {
      width: 100%;
      max-width: 500px;
      padding: 2em 0;
      ${mediaMax.tablet`
        margin: 0 auto;
      `}
      ${mediaMin.tablet`
        width: 50%;
        padding: 0 2em;
      `}
      h2 {
        font-weight: 300;
        margin-top: 0;
      }
      h3 {
        font-weight: 300;
      }

      p {
        font-family: 'Raleway', sans-serif;
        letter-spacing: 1px;
        font-size: 0.8em;
        text-align: justify;
      }
    }
  }
`;

ReactModal.setAppElement('#root');

const TeamModal = ({ modalOpen, toggleTeamModalOpen, currentEmployee, setCurrentEmployee }) => {
  const { name, position, bio, headshot } = currentEmployee;

  useEffect(() => {
    document.body.style.overflow = modalOpen ? 'hidden' : 'visible';
  }, [modalOpen]);

  return (
    <StyledReactModal
      closeTimeoutMS={200}
      isOpen={modalOpen}
      style={modalStyles}
      onRequestClose={() => toggleTeamModalOpen(false)}
      parentSelector={() => document.body}
      className="modal"
      styledPropOpen={modalOpen}
    >
      <button
        className="close-button"
        onClick={() => {
          setCurrentEmployee({});
          toggleTeamModalOpen(false);
        }}
      >
        <img src={closeIcon} alt="Close pupup" />
      </button>
      <div className="modal-container">
        <div className="left">
          <img src={headshot} alt={`${name}`} />
        </div>
        <div className="right">
          <h2>{name}</h2>
          <h3>{position}</h3>
          {bio && <div dangerouslySetInnerHTML={{ __html: bio.replace(/(\r\n|\n|\r)/gm, '') }} />}
        </div>
      </div>
    </StyledReactModal>
  );
};

export default TeamModal;
