/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import MediaQuery from 'react-responsive';

import { hashes } from 'data/routes';

import { routes } from 'data/routes';
import { mediaMax, mediaMin } from 'styles/mediaQueries';

import Hamburger from './Hamburger';
import MainLogo from './MainLogo';

let ScrollLink = Scroll.Link;

const HeaderContainer = styled.header`
  width: 100%;
  z-index: 101;

  &.absolute-header {
    height: 60px;
    background: transparent;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.navVisible ? '1' : '0')};
    visibility: ${props => (props.navVisible ? 'visible' : 'hidden')};
    ${mediaMin.tabletLandscape`
      height: 100px;
    `}
    nav {
      justify-content: center;
      ${mediaMin.tabletLandscape`
        justify-content: space-between;
      `}
    }
  }

  &.fixed-header {
    overflow: hidden;
    max-height: ${props => (props.hamburgerActive ? '100vh' : '60px')};
    background: #fff;
    color: #000;
    position: fixed;
    top: 0;
    transition: transform 300ms ease, max-height 0.5s ease-in-out;
    transform: ${props => (props.navVisible ? 'translateY(0)' : 'translateY(-100%)')};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    nav {
      ul {
        ${mediaMax.tabletLandscape`
          transition: opacity 300ms ease;
          opacity: ${props => (props.hamburgerActive ? 1 : 0)};
        `}
      }
      li {
        transition: opacity 300ms ease 200ms;
        opacity: ${props => (props.navVisible ? 1 : 0)};
        a {
          display: flex;
          align-items: center;
          height: 32px;
          padding: 0 10px;
        }
        .active {
          background-color: #000;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }

  nav {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${mediaMin.tabletLandscape`
      flex-direction: row;
    `}
    li {
      font-size: 0.8rem;
      ${mediaMin.tabletLandscape`
      font-size: .9rem;
      `}
      ${mediaMin.desktop`
        font-size: 1rem;
      `}
      &:last-child {
        font-weight: bold;
      }
    }
  }

  .modlin-group-logo {
    width: 250px;
    z-index: 1;
  }

  ul {
    padding: 0;
    li {
      list-style-type: none;
      text-transform: uppercase;
      margin: 16px 0;
      display: flex;
      justify-content: center;
      ${mediaMin.tabletLandscape`
        margin: 0 .5em;
      `}
      &:last-child {
        margin-right: 0;
      }
    }
    ${mediaMin.tabletLandscape`
      display: flex;
    `}
  }
`;

const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
`;

const LinkContainer = styled.ul`
  ${props => (props.absolute ? 'display: none;' : '')}
  li {
    a {
      color: ${props => props.color || '#000'};
    }
  }
`;

const Links = ({ color, currentRoute, absolute, setHamburgerActive }) => {
  if (currentRoute === 'home') {
    return (
      <LinkContainer absolute={absolute} color={color}>
        {routes.map(route => (
          <li key={`${route.title}`}>
            {route.anchorLink ? (
              <ScrollLink
                activeClass="active"
                to={route.scrollToElement}
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                href={route.href}
                onClick={() => {
                  setHamburgerActive(false);
                }}
              >
                {route.title}
              </ScrollLink>
            ) : (
              <NavLink
                activeClassName="active"
                to={route.href}
                onClick={() => {
                  setHamburgerActive(false);
                }}
              >
                {route.title}
              </NavLink>
            )}
          </li>
        ))}
      </LinkContainer>
    );
  } else {
    return (
      <LinkContainer absolute={absolute} color={color}>
        {routes.map(route => (
          <li key={`${route.title}`}>
            <NavLink
              activeClassName="active"
              to={route.href}
              onClick={() => {
                setHamburgerActive(false);
              }}
            >
              {route.title}
            </NavLink>
          </li>
        ))}
      </LinkContainer>
    );
  }
};

const Header = ({ location }) => {
  const [navVisible, setNavVisible] = useState(false);

  const [currentRoute, setCurrentRoute] = useState('home');
  const [hamburgerActive, setHamburgerActive] = useState(false);

  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
  const [scrollY, setScrollY] = useState(bodyOffset.top);

  const toggleHamburger = () => {
    setHamburgerActive(!hamburgerActive);
  };

  useEffect(() => {
    setNavVisible(false);
    setCurrentRoute(location.pathname.split('/')[1].toLowerCase() || 'home');
  }, []);

  useEffect(() => {
    setCurrentRoute(location.pathname.split('/')[1].toLowerCase() || 'home');
  }, [location]);

  useEffect(() => {
    if (currentRoute !== 'home') {
      setNavVisible(true);
    }
  }, [currentRoute]);

  const scrollListener = e => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    scrollY > 500 ? setNavVisible(true) : setNavVisible(false);
  };

  useEffect(() => {
    setNavVisible(false);
    Scroll.scroller.scrollTo(hashes[location.hash], {
      duration: 1500,
      delay: 100,
      smooth: true
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });

  return (
    <React.Fragment>
      <HeaderContainer className="absolute-header" navVisible={!navVisible}>
        <nav>
          <Link to="/">
            <MainLogo fill={currentRoute === 'home' ? '#FFF' : '#000'} />
          </Link>
          <Links
            setHamburgerActive={setHamburgerActive}
            absolute
            color={currentRoute === 'home' ? '#FFF' : '#000'}
            currentRoute={currentRoute}
          />
        </nav>
      </HeaderContainer>
      <HeaderContainer hamburgerActive={hamburgerActive} className="fixed-header" navVisible={navVisible}>
        <nav>
          <MediaQuery maxWidth={1023}>
            <MobileRow>
              <Link to="/">
                <MainLogo />
              </Link>
              <Hamburger color="#000" toggleHamburger={toggleHamburger} />
            </MobileRow>
            <Links setHamburgerActive={setHamburgerActive} currentRoute={currentRoute} />
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <Link to="/">
              <MainLogo />
            </Link>
            <Links setHamburgerActive={setHamburgerActive} currentRoute={currentRoute} />
          </MediaQuery>
        </nav>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default withRouter(Header);
