import React from 'react';
import styled from 'styled-components';

const ContactCardContainer = styled.a`
  width: 100%;
  padding: 50px 0;
  margin: 0 1em;
  transform: scale(1);
  transition: transform 200ms ease;
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 50px;
    display: block;
    margin: 0 auto;
  }
  p {
    color: #fff;
    font-weight: bolder;
  }
`;

function ContactCard({ iconAlt, iconSrc, details, linkTo }) {
  return (
    <ContactCardContainer href={linkTo} target="_blank" rel="noopener noreferrer">
      <img src={iconSrc} alt={iconAlt} />
      <p className="center">{details}</p>
    </ContactCardContainer>
  );
}

export default ContactCard;
