import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import introCarouselArray from 'data/introCarousel';
import CoverImage from 'components/images/CoverImage';

const bounce = keyframes`
  0%, 40%, 80%, 100% {
    transform: translateY(0);
  } 

  20%, 60% {
    transform: translateY(-20px);
  } 
`;

const IntroSliderContainer = styled.div`
  height: 100%;
  width: 100%;
  posiition: relative;

  .modlin-group-logo {
    position: absolute;
    max-width: 1000px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .down_arrow {
    display: block;
    position: absolute;
    width: 50px;
    max-width: 50px;
    bottom: 5%;
    left: calc(50% - 25px);
    animation: ${bounce} 10s linear infinite;
    z-index: 1;
  }
`;

const IntroSlider = () => {
  const [activeImage, setActiveImage] = useState(0);
  const imageCount = introCarouselArray.length - 1;

  useEffect(() => {
    const changeImage = () => {
      const nextImage = activeImage === imageCount ? 0 : activeImage + 1;
      setActiveImage(nextImage);
    };

    let timer1;
    if (activeImage === 0) {
      timer1 = setTimeout(changeImage, 8000);
    } else {
      timer1 = setTimeout(changeImage, 5000);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [activeImage, imageCount]);

  const generateCarousel = () => {
    return introCarouselArray.map((image, index) => {
      const { src, caption, quote } = image;
      return (
        <CoverImage
          key={`cover-image-${index}`}
          active={activeImage === index}
          src={src}
          caption={caption}
          quote={quote}
        />
      );
    });
  };

  return (
    <IntroSliderContainer>
      {generateCarousel()}
      <img className="down_arrow" src="/images/icons/down_arrow.svg" alt="Down Arrow" />
    </IntroSliderContainer>
  );
};

export default IntroSlider;
