import React from 'react';
import styled from 'styled-components';

import { parsePhone, sanitizePhone } from 'helpers/listings';

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 24px 32px;
  box-sizing: border-box;
  background-color: #edebeb;
  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a.phone {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: 300;
  }
`;

const Contact = ({ agent }) => {
  const { first_name, last_name, phone, email } = agent;
  return (
    <ContactCard>
      <span className="title">LISTING AGENT</span>
      <span className="title">{`${first_name} ${last_name}`}</span>
      <a href={`tel:${sanitizePhone(phone)}`} className="phone">{`O: ${parsePhone(phone)}`}</a>
      <a href={email}>EMAIL ME</a>
    </ContactCard>
  );
};

export default Contact;
