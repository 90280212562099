export const localNewsData = [
  {
    id: 32,
    date: '2020-06-05T16:32:53',
    date_gmt: '2020-06-05T16:32:53',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_news&#038;p=32'
    },
    modified: '2020-06-05T22:00:04',
    modified_gmt: '2020-06-05T22:00:04',
    slug: 'philip-falcone-sets-nyc-record-with-nearly-80-million-townhouse-sale',
    status: 'publish',
    type: 'modlin_group_news',
    link:
      'https://cms.modlingroup.dbox.com/modlin_group_news/philip-falcone-sets-nyc-record-with-nearly-80-million-townhouse-sale/',
    title: {
      rendered: 'Philip Falcone Sets NYC Record With Nearly $80 Million Townhouse Sale'
    },
    content: {
      rendered: '',
      protected: false
    },
    featured_media: 0,
    template: '',
    acf: {
      publication: 'THE WALL STREET JOURNAL',
      date: 'June 4, 2019',
      title: 'Philip Falcone Sets NYC Record With Nearly $80 Million Townhouse Sale',
      url:
        'https://www.wsj.com/articles/philip-falcone-sets-nyc-record-with-nearly-80-million-townhouse-sale-11559688915',
      image: 'https://cms.modlingroup.dbox.com/wp-content/uploads/2020/06/news-WSJ_500.jpg'
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news/32'
        }
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news'
        }
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_news'
        }
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=32'
        }
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true
        }
      ]
    }
  },
  {
    id: 29,
    date: '2020-06-05T16:23:11',
    date_gmt: '2020-06-05T16:23:11',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_news&#038;p=29'
    },
    modified: '2020-06-05T16:23:11',
    modified_gmt: '2020-06-05T16:23:11',
    slug: 'a-rod-takes-bigger-swing-at-residential-real-estate-in-nyc',
    status: 'publish',
    type: 'modlin_group_news',
    link:
      'https://cms.modlingroup.dbox.com/modlin_group_news/a-rod-takes-bigger-swing-at-residential-real-estate-in-nyc/',
    title: {
      rendered: 'A-Rod takes bigger swing at residential real estate in NYC'
    },
    content: {
      rendered: '',
      protected: false
    },
    featured_media: 0,
    template: '',
    acf: {
      publication: 'THE NY POST',
      date: 'June 25, 2019',
      title: 'A-Rod takes bigger swing at residential real estate in NYC',
      url: 'https://nypost.com/2019/06/25/a-rod-takes-bigger-swing-at-residential-real-estate-in-nyc/',
      image: 'https://cms.modlingroup.dbox.com/wp-content/uploads/2020/06/news-NY_Post_500.png'
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news/29'
        }
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news'
        }
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_news'
        }
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=29'
        }
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true
        }
      ]
    }
  },
  {
    id: 26,
    date: '2020-06-05T16:20:12',
    date_gmt: '2020-06-05T16:20:12',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_news&#038;p=26'
    },
    modified: '2020-06-05T16:20:12',
    modified_gmt: '2020-06-05T16:20:12',
    slug: 'a-rods-nyc-real-estate-buying-spree-is-on-the-upswing',
    status: 'publish',
    type: 'modlin_group_news',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_news/a-rods-nyc-real-estate-buying-spree-is-on-the-upswing/',
    title: {
      rendered: 'A-Rod&#8217;s NYC real estate buying spree is on the upswing'
    },
    content: {
      rendered: '',
      protected: false
    },
    featured_media: 0,
    template: '',
    acf: {
      publication: 'THE REAL DEAL',
      date: 'June 26, 2019',
      title: "A-Rod's NYC real estate buying spree is on the upswing",
      url: 'https://therealdeal.com/2019/06/26/a-rods-nyc-real-estate-buying-spree-is-on-the-upswing/',
      image: 'https://cms.modlingroup.dbox.com/wp-content/uploads/2020/06/news-Real_Deal_500.jpg'
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news/26'
        }
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news'
        }
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_news'
        }
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=26'
        }
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true
        }
      ]
    }
  },
  {
    id: 22,
    date: '2020-06-02T20:28:04',
    date_gmt: '2020-06-02T20:28:04',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_news&#038;p=22'
    },
    modified: '2020-06-02T20:28:04',
    modified_gmt: '2020-06-02T20:28:04',
    slug: 'a-rod-corp-modlin-group-and-stonehenge-nyc-announce-a-new-real-estate-venture-together',
    status: 'publish',
    type: 'modlin_group_news',
    link:
      'https://cms.modlingroup.dbox.com/modlin_group_news/a-rod-corp-modlin-group-and-stonehenge-nyc-announce-a-new-real-estate-venture-together/',
    title: {
      rendered: 'A-Rod Corp, Modlin Group, and Stonehenge NYC Announce a New Real Estate Venture Together'
    },
    content: {
      rendered: '',
      protected: false
    },
    featured_media: 0,
    template: '',
    acf: {
      publication: 'Cision PR Newswire',
      date: 'June 26, 2020',
      title: 'A-Rod Corp, Modlin Group, and Stonehenge NYC Announce a New Real Estate Venture Together',
      url:
        'https://www.prnewswire.com/news-releases/a-rod-corp-modlin-group-and-stonehenge-nyc-announce-a-new-real-estate-venture-together-300875129.html',
      image: 'https://cms.modlingroup.dbox.com/wp-content/uploads/2020/06/news-PR_Newswire_500.png'
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news/22'
        }
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news'
        }
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_news'
        }
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=22'
        }
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true
        }
      ]
    }
  },
  {
    id: 20,
    date: '2020-06-02T20:26:30',
    date_gmt: '2020-06-02T20:26:30',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_news&#038;p=20'
    },
    modified: '2020-06-02T20:26:30',
    modified_gmt: '2020-06-02T20:26:30',
    slug: 'a-rod-is-coming-for-nyc-and-sofla-real-estate',
    status: 'publish',
    type: 'modlin_group_news',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_news/a-rod-is-coming-for-nyc-and-sofla-real-estate/',
    title: {
      rendered: 'A-Rod is coming for NYC and SoFla real estate'
    },
    content: {
      rendered: '',
      protected: false
    },
    featured_media: 0,
    template: '',
    acf: {
      publication: 'The Real Deal',
      date: 'October 1, 2019',
      title: 'A-Rod is coming for NYC and SoFla real estate ',
      url: 'https://therealdeal.com/issues_articles/a-rods-real-estate-playbook/',
      image: 'https://cms.modlingroup.dbox.com/wp-content/uploads/2020/06/news-Real_Deal_2_500.jpg'
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news/20'
        }
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_news'
        }
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_news'
        }
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=20'
        }
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true
        }
      ]
    }
  }
];
