import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { mediaMin } from 'styles/mediaQueries';

const NextArrow = ({ className, onClick }) => {
  return <img src="/images/icons/arrow-right.svg" alt="next arrow" className={className} onClick={onClick} />;
};

const PrevArrow = ({ className, onClick }) => {
  return <img src="/images/icons/arrow-left.svg" alt="previous arrow" className={className} onClick={onClick} />;
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

const SliderContainer = styled.div`
  opacity: ${props => (props.active ? '1' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  boottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      margin: 0 auto;
      height: 100%;
      width: 100%;
      ${mediaMin.tabletLandscape`
        width: 75%;
        `}
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }
    }
    .slick-arrow {
      display: none;
      ${mediaMin.tabletLandscape`
        display: block;
        `}
      height: 45px;
      z-index: 100;
    }
    .slick-next {
      right: 44px;
    }
    .slick-prev {
      left: 44px;
    }
  }
`;

const SliderSlide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-height: 75vh;
    max-width: 100%;
    margin: 0 auto;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  z-index: 150;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  top: 75px;
  right: 36px;
  &:focus {
    outline: none;
  }
  ${mediaMin.tabletLandscape`
    top: 24px;
    right: 44px;
    `}
`;

const generateSlides = photoArray => {
  return photoArray.map((el, idx) => {
    return (
      <SliderSlide key={el.src}>
        <img src={el} alt={el} />
      </SliderSlide>
    );
  });
};

const ImageGallery = ({ currentListing, toggleImageGallery, galleryActive }) => {
  return (
    <SliderContainer active={galleryActive}>
      <CloseButton onClick={toggleImageGallery}>
        <img src="/images/icons/close.svg" alt="close overlay" />
      </CloseButton>
      <Slider {...settings}>{generateSlides(currentListing.media.original_image_list)}</Slider>
    </SliderContainer>
  );
};

export default ImageGallery;
