/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Context from 'config/Context';
import ListingsHeading from './listings-heading';
import ListingsGrid from './listings-grid';
import { mediaMin } from 'styles/mediaQueries';

const ListingsContainer = styled.div`
  width: 100%
  padding: 80px 24px;
  box-sizing: border-box;
  ${mediaMin.tabletLandscape`
    padding: 140px 10%;
  `}
`;

const Listings = ({ match }) => {
  const context = useContext(Context);
  const { listings, fetchListingsData } = context;

  const [filter, setFilter] = useState('SALES');

  useEffect(() => {
    if (!listings) {
      fetchListingsData();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Modlin Group | Listings</title>
        <meta
          name="description"
          content="Modlin Group is a bespoke boutique real estate agency in New York City.  Founded by Adam D. Modlin in 1999, the company has a 20-year track record for closing high profile transactions."
        />
        <meta property="og:title" content="Modlin Group" />
        <meta
          property="og:description"
          content="MODLIN GROUP — “A curated, bespoke, family office for Real Estate” – Adam D. Modlin"
        />
        <meta property="og:image" content="/images/modlin-group.jpg" />
      </Helmet>
      <ListingsContainer className="listings-container">
        <ListingsHeading filter={filter} setFilter={setFilter} />
        <ListingsGrid filter={filter} match={match} />
      </ListingsContainer>
    </>
  );
};

export default Listings;
