import React from 'react';
import styled from 'styled-components';

const SVGContainer = styled.svg`
  transition: fill 300ms ease;
  fill: ${props => props.fill || '#000'};
`;

function MainLogo({ fill }) {
  return (
    <SVGContainer className="modlin-group-logo" fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.3 44.6">
      <title>Modlin Main Logo</title>
      <polygon points="7.1 20.9 24.9 20.9 23.5 23.3 7.1 23.3 7.1 20.9" />
      <path d="M41,44.6h2.4v-12A25.45,25.45,0,0,1,41,36.5Z" />
      <path d="M.1,44.6H2.5v-8A31.42,31.42,0,0,1,.1,32.7Z" />
      <path d="M21.7,2.9a19.53,19.53,0,0,1,11.8,4l1.3-2.1a21.55,21.55,0,0,0-26.1,0L10,6.9a19,19,0,0,1,11.7-4" />
      <path d="M2.5,4l8.8,14.5h2.8L2.9,0H.1V11.7A25.45,25.45,0,0,1,2.5,7.8Z" />
      <polygon points="40.6 0 21.6 30.9 18.5 25.7 15.7 25.7 20.3 33.3 23 33.3 41 4 41 18.5 43.4 18.5 43.4 0 40.6 0" />
      <path d="M33.3,20.9l-1.5,2.4h9A19.22,19.22,0,0,1,2.4,22.1a18.86,18.86,0,0,1,2.5-9.4L3.5,10.4A21.58,21.58,0,1,0,43.2,22.1V20.9Z" />
      <path d="M189.7,13.3h1.9V31.2h-2.2l-12-14.9V31.2h-1.9V13.3h2l12.2,15.2V13.3ZM164.1,31.2H166V13.3h-1.9Zm54.1-7.3h8.9v3.5a10.51,10.51,0,0,1-6.8,2.3,8,8,0,0,1-5.5-2.2,7,7,0,0,1-2.3-5.2,7.14,7.14,0,0,1,2.3-5.2,8.15,8.15,0,0,1,5.5-2.2h0a8.9,8.9,0,0,1,6.8,3.1l1.5-1.1a10.63,10.63,0,0,0-8.3-3.9h0a9.87,9.87,0,0,0-6.8,2.7,9,9,0,0,0,0,13.2,10.05,10.05,0,0,0,6.8,2.7c3.3,0,6.6-1.2,8.4-3.1l.3-.3V22H218.2Zm102.1-5.1a5.46,5.46,0,0,1-5.4,5.5H307v6.9h-1.9V13.4h9.8a5.38,5.38,0,0,1,5.4,5.4m-1.9,0a3.48,3.48,0,0,0-3.5-3.5H307v7.1h7.9a3.56,3.56,0,0,0,3.5-3.6m-71.3,5.5,4.4,6.9h-2.3l-4.4-6.9H239v6.9h-1.8V13.4H247a5.46,5.46,0,0,1,5.5,5.4,5.67,5.67,0,0,1-5.4,5.5m3.4-5.5a3.5,3.5,0,0,0-3.6-3.5H239v7.1h7.9a3.59,3.59,0,0,0,3.6-3.6M146.2,13.4h-1.9V31.2h12.8V29.3H146.2Zm-9,8.9a8.92,8.92,0,0,1-8.9,8.9h-7.7V13.4h7.7a8.92,8.92,0,0,1,8.9,8.9m-1.9,0a7,7,0,0,0-7-7h-5.8v14h5.8a7,7,0,0,0,7-7m160.8,1.6a5.8,5.8,0,0,1-11.6,0V13.3h-1.9V23.9a7.6,7.6,0,0,0,7.6,7.6,7.68,7.68,0,0,0,7.7-7.6V13.4H296l.1,10.5Zm-19.3-1.6a9.3,9.3,0,1,1-9.3-9.3,9.35,9.35,0,0,1,9.3,9.3m-1.9,0a7.4,7.4,0,1,0-7.4,7.4,7.38,7.38,0,0,0,7.4-7.4m-161.4,0a9.3,9.3,0,1,1-9.3-9.3,9.35,9.35,0,0,1,9.3,9.3m-1.9,0a7.4,7.4,0,1,0-7.4,7.4,7.38,7.38,0,0,0,7.4-7.4M79,26.5,72.1,13.3h-2V31.2H72v-14l6.4,12.3h1.1l6.4-12.2V31.2h1.9V13.3H85.9Z" />
    </SVGContainer>
  );
}

export default MainLogo;
