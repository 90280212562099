/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { IntroSlider, Story, News, Services, Team, Contact } from './components/_module';

const Home = ({ location }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Modlin Group | Home</title>
        <meta
          name="description"
          content="Modlin Group is a bespoke boutique real estate agency in New York City.  Founded by Adam D. Modlin in 1999, the company has a 20-year track record for closing high profile transactions."
        />
        <meta property="og:title" content="Modlin Group" />
        <meta
          property="og:description"
          content="MODLIN GROUP — “A curated, bespoke, family office for Real Estate” – Adam D. Modlin"
        />
        <meta property="og:image" content="/images/modlin-group.jpg" />
      </Helmet>
      <IntroSlider />
      <Story />
      {/* <News /> */}
      <Services />
      <Team />
      <Contact />
    </React.Fragment>
  );
};

export default withRouter(Home);
