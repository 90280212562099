import React from 'react';
import styled from 'styled-components';

import { isTownhouse, numberWithCommas, parseBathrooms, parsePricePerSQFT, SFtoSM, parseSeasonlPrice } from 'helpers/listings';
import { variables } from 'styles/variables';

const FactSectionContainer = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  border: 1px solid ${variables.colors.lightGray};
  display: flex;
  flex-direction: column;
`;

const BigFact = styled.h5`
  margin: 0 0 24px 0;
  font-size: 18px;
  line-height: 24px;

  &:nth-of-type(4) {
    margin: 24px 0;
  }

  &:nth-of-type(5) {
    margin: 24px 0;
  }

  &.unit-number {
    font-size: 20px;
  }
`;

const FactRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;

  span {
    font-size: 14px;
    line-height: 18px;
  }
`;

const parseFeatures = (features, margin) => {
  const parsedFeatures = Object.keys(features).map((feature, idx) => {
    if (feature === 'doorman' || feature === 'pets' || feature === 'prewar') {
      return null;
    }
    if (features[feature]) {
      let title = feature.split('_');
      title.forEach((el, idx) => {
        title[idx] = el[0].toUpperCase() + el.slice(1);
      });
      return (
        <FactRow key={title.join('')}>
          <span>{title.join(' ')}</span>
          <span>{ }</span>
        </FactRow>
      );
    } else {
      return null;
    }
  }).filter(feature => !!feature);

  if (margin) {
    parsedFeatures.push(<FactRow key="margin-bottom" marginBottom />);
  }
  return parsedFeatures;
};

const parseBuildingType = currentListing => {
  if (currentListing.building_amenities.prewar) {
    return 'Pre-War';
  }
  if (currentListing.features.new_development) {
    return 'New Development';
  }
  return 'Post-War';
};

const FactSection = ({ currentListing }) => {
  const isTownhouseBool = isTownhouse(currentListing.unit_details.property_type);

  return currentListing.unit_details.property_type !== 'Land' ? (
    <FactSectionContainer>
      <BigFact>GENERAL DETAILS</BigFact>
      <BigFact className="unit-number">{currentListing.unit_details.unit_number}</BigFact>
      <BigFact>
        {currentListing.sale_rent && currentListing.sale_rent === 'R'
          ? currentListing.listing_details.seasonal_rent_price && currentListing.listing_details.seasonal_rent_price.length
            ? parseSeasonlPrice(currentListing.listing_details.seasonal_rent_price).reduce(((result, item) => result ? <>{result}<br />{item}</> : <>{item}</>))
            : 'Please inquire'
          : currentListing.listing_details.current_price && currentListing.listing_details.current_price !== 1 ? `$${numberWithCommas(currentListing.listing_details.current_price)}` : 'PRICE UPON REQUEST'
        }
      </BigFact>
      {currentListing.unit_details.maint_cc ? (
        <FactRow key="1">
          <span>{currentListing.unit_details.property_type === 'Co-op' ? 'Maintenance' : 'Common Charges'}</span>
          <span>{`$${numberWithCommas(currentListing.unit_details.maint_cc)}`}</span>
        </FactRow>
      ) : (
        ''
      )}
      <FactRow key="2">
        <span>{`${isTownhouseBool ? 'Monthly' : ''} RE Taxes`}</span>
        <span>{`$${numberWithCommas(currentListing.unit_details.real_estate_tax)}`}</span>
      </FactRow>
      {currentListing.unit_details.max_financing_pct && (
        <FactRow key="3">
          <span>Financing Allowed</span>
          <span>{`${currentListing.unit_details.max_financing_pct}%`}</span>
        </FactRow>
      )}
      {currentListing.unit_details.sqft ? (
        <FactRow key="4">
          <span>Price Per SF</span>
          <span>
            {parsePricePerSQFT(
              currentListing.listing_details.current_price,
              currentListing.unit_details.sqft,
              currentListing.listing_details.listing_type
            )}
          </span>
        </FactRow>
      ) : (
        ''
      )}
      <FactRow key="5">
        <span>Status</span>
        <span>{currentListing.listing_details.status}</span>
      </FactRow>
      <FactRow key="6">
        <span>Rooms</span>
        <span>{currentListing.unit_details.total_rooms}</span>
      </FactRow>
      <FactRow key="7">
        <span>Bedrooms</span>
        <span>{currentListing.unit_details.beds}</span>
      </FactRow>
      <FactRow key="8">
        <span>Bathrooms</span>
        <span>{parseBathrooms(currentListing.unit_details.full_baths, currentListing.unit_details.half_baths)}</span>
      </FactRow>
      {currentListing.unit_details.sqft && (
        <FactRow key="9">
          <span>ASF | SM</span>
          <span>{`${currentListing.unit_details.sqft} | ${SFtoSM(currentListing.unit_details.sqft)}`}</span>
        </FactRow>
      )}
      <FactRow key="10">
        <span>Ownership</span>
        <span>{currentListing.unit_details.property_type}</span>
      </FactRow>
      {(currentListing.building_amenities.prewar || currentListing.features.new_development) && (
        <FactRow key="11">
          <span>Building Type</span>
          <span>{parseBuildingType(currentListing)}</span>
        </FactRow>
      )}
      {currentListing.location.city === 'New York' &&
        <FactRow key="12">
          <span>Floors | Apts</span>
          <span>{`${(currentListing.building_details || { total_stories: '-' }).total_stories} | ${(currentListing.building_details || { total_units: '-' }).total_units}`}</span>
        </FactRow>
      }
      {!isTownhouseBool && currentListing.location.city === 'New York' && (
        <FactRow key="13">
          <span>Service Level</span>
          <span>{currentListing.building_amenities.doorman ? 'Doorman' : 'No Doorman'}</span>
        </FactRow>
      )}
      {!isTownhouseBool && currentListing.location.city === 'New York' && (
        <FactRow key="14" marginBottom>
          <span>Pet Policy</span>
          <span>{currentListing.building_amenities.pets ? 'Yes' : 'No'}</span>
        </FactRow>
      )}
      {
        (!isTownhouseBool && parseFeatures(parseFeatures(currentListing.unit_amenities, false)).length > 0) &&
        <>
          <BigFact>APARTMENT FEATURES</BigFact>
          {parseFeatures(currentListing.unit_amenities, true)}
        </>
      }
      {
        (!isTownhouseBool && parseFeatures(parseFeatures(currentListing.building_amenities, false)).length > 0) &&
        <>
          <BigFact>BUILDING FEATURES</BigFact>
          {parseFeatures(currentListing.building_amenities, true)}
        </>
      }
    </FactSectionContainer>
  ) : (
    <FactSectionContainer>
      <BigFact>GENERAL DETAILS</BigFact>
      <BigFact className="unit-number">{currentListing.unit_details.unit_number}</BigFact>
      <BigFact>
        {currentListing.listing_details.current_price ? `$${numberWithCommas(currentListing.listing_details.current_price)}` : 'PRICE UPON REQUEST'}
      </BigFact>
      <FactRow key="5">
        <span>Status</span>
        <span>{currentListing.listing_details.status}</span>
      </FactRow>
      <FactRow key="10">
        <span>Ownership</span>
        <span>{currentListing.unit_details.property_type}</span>
      </FactRow>
    </FactSectionContainer>
  );
};

export default FactSection;
