import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Markup } from 'interweave';

import Context from 'config/Context';

import { mediaMin } from 'styles/mediaQueries';

const StoryContainer = styled.div`
  img {
    max-width: 100%;
    height: auto !important;
    width: 100% !important;
    padding: 0 !important;
  }
  h2 {
    text-align: left;
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    ${mediaMin.tabletLandscape`
      text-align: justify;
      text-justify: inter-word;
      padding: 0 10%;
    `}
  }
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    ${mediaMin.tabletLandscape`
      text-align: justify;
      text-justify: inter-word;
      padding: 0 10%;
    `}
  }
  img {
    margin: 24px 0;
    ${mediaMin.tabletLandscape`
      padding: 0 10%;
    `}
  }
`;

const Story = () => {
  const context = useContext(Context);
  const { homePageSections, fetchHomePageSections } = context;

  // Keep this here to trigger API call for page supplementary sections
  useEffect(() => {
    if (!homePageSections) {
      fetchHomePageSections();
    }
  }, [homePageSections]);

  return (
    <Element name="story-scroll-element">
      <StoryContainer className="container standard-padding" id="story">
        <h2 className="section-title center">Story</h2>
        {homePageSections && (
          <Markup content={homePageSections.find(obj => obj.page_section === 'story').page_content} />
        )}
      </StoryContainer>
    </Element>
  );
};

export default Story;
