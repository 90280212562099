import React, { useContext } from 'react';
import styled from 'styled-components';

import Context from 'config/Context';
import { mediaMin } from 'styles/mediaQueries';

import ListingCard from './listing-card';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${mediaMin.tabletLandscape`
    margin-top: 30px;
  `}
`;

const isRental = listing => {
  if ((listing.sale_rent && listing.sale_rent === 'R') || (listing.listing_details && listing.listing_details.listing_type === 'R')) {
    return true
  }

  // const values = Object.values(listing.rental_details);
  // if (values.every(el => el === '')) {
  //   return false;
  // }
  // if (values.length > 0) {
  //   return true;
  // }
  return false;
};

const ListingsGrid = ({ filter, match }) => {
  const context = useContext(Context);
  const listings = context.getCurrentListings(match.path);

  const generateGrid = () => {
    if (listings) {
      let filteredListings;
      let rentalBool;
      if (filter === 'SALES') {
        filteredListings = listings.filter(listing => !isRental(listing));
        rentalBool = false;
      } else if (filter === 'RENTALS') {
        filteredListings = listings.filter(listing => isRental(listing));
        rentalBool = true;
      } else {
        filteredListings = [];
      }

      return filteredListings && filteredListings.length ? filteredListings.map((listing, idx) => (
        <ListingCard key={`listing-card-${idx}`} rentalBool={rentalBool} listing={listing} idx={idx} baseRoute={context.getCurrentListingsBaseRoute(match.path)} />
      )) : (<div style={{ textAlign: 'center', marginTop: '100px' }}>No listings available at the moment</div>);
    }

    return null;
  };

  return <Grid>{generateGrid()}</Grid>;
};

export default ListingsGrid;
