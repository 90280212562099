import React from 'react';
import styled from 'styled-components';

const ListingBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  p {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
    text-justify: inter-word;
  }
`;

const ListingBody = ({ currentListing }) => {
  return (
    <ListingBodyContainer>
      <p>{currentListing.listing_details.description}</p>
    </ListingBodyContainer>
  );
};

export default ListingBody;
