import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import Context from 'config/Context';

import TeamModal from './TeamModal';
import TeamCard from './TeamCard';

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
`;

const FilterButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
`

const FilterItem = styled.div`
  border-bottom: ${({ active }) => active && '1px solid #000'};
  cursor: pointer;
`


const Team = () => {
  const context = useContext(Context);
  const { fetchTeam, team } = context;

  const [location, setLocation] = useState('MANHATTAN')
  const [teamModalOpen, toggleTeamModalOpen] = useState(false);
  const [currentTeamModalEmployee, setCurrentTeamModalEmployee] = useState({});

  const withoutExecutive = useMemo(() =>
    team?.filter(agent => !agent.executive)
      .map(elem => elem.location ? elem : { ...elem, location: 'MANHATTAN' })
    , [team])

  const withExecutive = useMemo(() => team?.find(agent => agent.executive), [team])


  const generateEmployeeCards = useCallback(agentArray => {
    return agentArray
      .filter(el => el.location === location)
      .map(employee => (
        <TeamCard
          toggleTeamModalOpen={toggleTeamModalOpen}
          setCurrentTeamModalEmployee={setCurrentTeamModalEmployee}
          key={employee.name}
          employee={employee}
        />
      ));
  }, [location]);

  useEffect(() => {
    if (!team) {
      fetchTeam();
    }
  }, [team]);


  return (
    <>
      <TeamModal
        modalOpen={teamModalOpen}
        toggleTeamModalOpen={toggleTeamModalOpen}
        setCurrentEmployee={setCurrentTeamModalEmployee}
        currentEmployee={currentTeamModalEmployee}
      />
      <Element name="team-scroll-element">
        <div className="standard-padding team-container" id="story">
          <h2 className="section-title center">Team</h2>
          <FilterButtonsWrapper>
            <FilterItem active={location === 'MANHATTAN'} onClick={() => setLocation('MANHATTAN')}>MANHATTAN</FilterItem>
            <FilterItem active={location === 'HAMPTONS'} onClick={() => setLocation('HAMPTONS')}>HAMPTONS</FilterItem>
          </FilterButtonsWrapper>
          <TeamContainer>
            <CardContainer center>
              {withExecutive ? <TeamCard
                toggleTeamModalOpen={toggleTeamModalOpen}
                setCurrentTeamModalEmployee={setCurrentTeamModalEmployee}
                key={withExecutive.name}
                employee={withExecutive}
              /> : null}
            </CardContainer>
          </TeamContainer>
          <TeamContainer>
            <CardContainer>{team ? generateEmployeeCards(withoutExecutive) : null}</CardContainer>
          </TeamContainer>
        </div>
      </Element>
    </>
  );
};

export default Team;
