import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';

import Context from 'config/Context';

import { mediaMin } from 'styles/mediaQueries';

const ServicesContainer = styled.div`
  background-image: url('/images/pages/home/services/sculpture_bg.jpg');
  width: 100%;
  color: #fff;
  h2 {
    text-align: left;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    ${mediaMin.tablet`
      font-size: 40px;
    `}
    ${mediaMin.tabletLandscape`
      padding: 0 10%;
    `}
  }
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    ${mediaMin.tabletLandscape`
      padding: 0 10%;
      text-align: justify;
      text-justify: inter-word;
    `}
  }
`;

const SuppmentarySection = () => {
  const context = useContext(Context);
  const { homePageSections, fetchHomePageSections } = context;

  return (
    <ServicesContainer>
      <div className="container standard-padding">
        {homePageSections && (
          <Markup content={homePageSections.find(obj => obj.page_section === 'supplementary_section').page_content} />
        )}
      </div>
    </ServicesContainer>
  );
};

export default SuppmentarySection;
