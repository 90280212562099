import { createGlobalStyle } from 'styled-components';
import { mediaMin } from 'styles/mediaQueries';

const Container = createGlobalStyle`
  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1000px;
  }
  .team-container {
    width: 90%;
    margin: 0 auto;
    max-width: 800px;
  }
  .standard-padding {
    padding: 20px 0;
    ${mediaMin.tabletLandscape`
      padding: 100px 0;
    `}
  }
`;

export default Container;
