import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { mediaMin } from 'styles/mediaQueries';
import Context from 'config/Context';

const NewDevelopmentContainer = styled.div`
    width: 100%
    padding: 80px 24px;
    box-sizing: border-box;
    ${mediaMin.tabletLandscape`
    padding: 140px 10%;
    `}
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
        flex-direction: row;
    `}
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 24px;
  ${mediaMin.tabletLandscape`
        font-size: 40px;
        line-height: 40px;
    `}
`;

const NewDevelopment = () => {
  const context = useContext(Context);
  const { setNavVisible } = context;

  useEffect(() => {
    setNavVisible(true);
  });

  return (
    <>
      <Helmet>
        <title>Modlin Group | New Developments</title>
        <meta
          name="description"
          content="Modlin Group is a bespoke boutique real estate agency in New York City.  Founded by Adam D. Modlin in 1999, the company has a 20-year track record for closing high profile transactions."
        />
        <meta property="og:title" content="Modlin Group" />
        <meta
          property="og:description"
          content="MODLIN GROUP — “A curated, bespoke, family office for Real Estate” – Adam D. Modlin"
        />
        <meta property="og:image" content="/images/modlin-group.jpg" />
      </Helmet>
      <NewDevelopmentContainer>
        <Heading>
          <Title>New Developments</Title>
        </Heading>
      </NewDevelopmentContainer>
    </>
  );
};

export default NewDevelopment;
