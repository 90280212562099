const routes = [
  {
    title: 'Story',
    href: '/#story',
    anchorLink: true,
    scrollToElement: 'story-scroll-element'
  },
  {
    title: 'Team',
    href: '/#team',
    anchorLink: true,
    scrollToElement: 'team-scroll-element'
  },
  {
    title: 'Contact',
    href: '/#contact',
    anchorLink: true,
    scrollToElement: 'contact-scroll-element'
  },
  // {
  //   title: 'New Developments',
  //   href: '/new-developments',
  //   anchorLink: false,
  // },
  {
    title: 'Listings',
    href: '/listings',
    anchorLink: false
  }
];

const hashes = {
  '#story': 'story-scroll-element',
  '#contact': 'contact-scroll-element',
  '#team': 'team-scroll-element'
};

export { routes, hashes };
