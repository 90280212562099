import { createGlobalStyle } from 'styled-components';
import { mediaMin } from 'styles/mediaQueries';

const Typographystyles = createGlobalStyle`
  h1, h2, h3, h4, h5, h6, li {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    letter-spacing: .1rem;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  
  
  a {
    &:link,
    &:active,
    &:focus,
    &:visited {
      color: #000;
    }
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

 

  .section-title {
    font-size: 2rem;
    font-weight: 200;
    ${mediaMin.phoneLarge`
      font-size: 2.4rem;
    `}
    ${mediaMin.tablet`
      font-size: 2.8rem;
    `}
    ${mediaMin.tabletLandscape`
      font-size: 3.2rem;
    `}
  }

  .standard-paragraph {
    font-size: 1rem;
    ${mediaMin.tabletLandscape`
      font-size: 1.4rem;
    `}
  }

  .underline {
    text-decoration: underline;
  }

  .center {
    text-align: center;
  }

`;

export default Typographystyles;
